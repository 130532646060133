import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Carbon is always getting better. This happens through feature and usability upgrades to our elements and components. It also happens through exploration and experimentation. The Carbon team will surface these explorations here.`}</p>
    </PageDescription>
    <h2 {...{
      "id": "experimental-resources-coming-soon"
    }}>{`Experimental resources coming soon`}</h2>
    <p>{`We are actively seeking to include these components, patterns, and tools in the Carbon site.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Resource`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pattern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/carbon-design-system/carbon/issues/3071"
            }}>{`Axure kit`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cards`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pattern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pattern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pattern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error states`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pattern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pattern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Settings page`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In progress`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "how-experimental-works"
    }}>{`How experimental works`}</h2>
    <p>{`We use experimental projects to surface future resources and get them into the hands of teams who will use them. Expect these components, patterns, and tools to change over time. They may have some non-functional pieces, or be lacking in documentation. `}<strong parentName="p">{`There is no guarantee of support`}</strong>{`.`}</p>
    <p>{`If you choose to use experimental resources, you can help move them closer to production by contributing feedback, code, and design. Through contributions and feedback, some of these resources will become core parts of the Carbon Design System.`}</p>
    <h3 {...{
      "id": "status-definitions"
    }}>{`Status definitions`}</h3>
    <p><strong parentName="p">{`Experimental status means duplicate patterns may exist.`}</strong>{` Accompanying guidance may be minimal. Maintainers are not yet identified or committed. Inclusion in the shared library raises awareness and acts as a live audit so people can benefit from the collaboration.`}</p>
    <p><strong parentName="p">{`Beta status means there are few or no duplicate patterns.`}</strong>{` Guidance on the site is reasonably complete and useful. Maintainers are identified and committed to ongoing support. The intention is to go to production with only minor changes based on user feedback.`}</p>
    <p><strong parentName="p">{`Production status there are no duplicate patterns.`}</strong>{` These offer high-quality guidance. Design symbols and code components are available. Maintainers know to monitor support channels for their patterns.`}</p>
    <h3 {...{
      "id": "feature-flags"
    }}>{`Feature-flags`}</h3>
    <p>{`Carbon uses `}<inlineCode parentName="p">{`feature-flags`}</inlineCode>{` to toggle new features on and off.`}</p>
    <p>{`To turn on a feature flag, include the `}<strong parentName="p">{`feature-flag`}</strong>{` variable into your SCSS file before importing `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$feature-flags: (
  grid-columns-16: true,
);
@import 'carbon-components/scss/globals/scss/styles.scss';
`}</code></pre>
    <p><em parentName="p">{`Note: You must be using Sass to take advantage of code using feature flags. The Sass feature flags determine which CSS is compiled.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      